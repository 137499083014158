import { mindboxGetEventRecommendation } from "@/utils/api/mindbox";
import { Preloader } from "../loading/preloader";
import { FC, useEffect, useState } from "react";
import { getItemsList } from "@/utils/api/catalog";
import { SwiperCardList } from "../swiper-card-list/swiper-card-list";
import { usePathname } from "next/navigation";

interface IPersonalRecommendedComponent {}

export const PersonalRecommendedComponent: FC<
  IPersonalRecommendedComponent
> = () => {
  const pathname = usePathname();
  const [isVisible, setIsVisible] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [items, setItems] = useState([]);
  const handleRecommendedClick = () =>
    ym(42989679, "reachGoal", "goToProductFromRecommendations");
  useEffect(() => {
    mindboxGetEventRecommendation()
      .then((res) => {
        const ids = res.data.recommendations
          .map((item) =>
            !pathname.includes(item.ids.website) ? item.ids.website : 0
          )
          .join(",");
        getItemsList(ids, true, false, false, true)
          .then((res) => {
            if (res.items.items.length === 0) return setIsVisible(false);
            setItems(res.items.items);
            setIsLoading(false);
          })
          .catch((err) => {
            setIsVisible(false);
          });
      })
      .catch((err) => {
        setIsVisible(false);
      });
  }, []);
  return (
    isVisible && (
      <section>
        {isLoading ? (
          <Preloader />
        ) : (
          <SwiperCardList
            isVisibleTitle
            hasLink={false}
            title={"Может быть интересно"}
            items={items}
            onClick={handleRecommendedClick}
          />
        )}
      </section>
    )
  );
};
